<template>
   <div class="container">
     <div class="col-md-4 col_item" v-show="gishuun_bus.torol_id === 4"  v-for="(gishuun_bus, uilchil_index) in gishuun_bus_bagtsArray" :key="uilchil_index">
       <div style="margin: 15px 0; " class="gold_silver_bronze " v-if="uilchil_index === 3" >
         <div class="gold_header" :style="`background-color: ${gishuun_bus.ongo}`">
           <div class="head_text">
             <h5>{{ gishuun_bus.torol }}</h5>
           </div>
         </div>
         <div class="col_body1">
           <div class="text_Body1">{{ 'service_date' | translate }} :
             <p>{{ gishuun_bus.uilchilgee_sar }}</p>
           </div>
           <div class=" text_Body1 last_text_body_last">{{ 'job_posting' | translate }}:
             <table>
               <tbody>
               <tr id="btn_bottom">
                 <td>{{ gishuun_bus.zar_neg }}</td>
                 <td>{{ gishuun_bus.zar_hoyor }}</td>
                 <td>{{ gishuun_bus.zar_gurav }}</td>
               </tr>
               </tbody>
             </table>
             <div class="last_text">

             </div>
             <div class="last_text_body_last">{{ 'package_service1' | translate }} :
               <table>
                 <tbody>
                 <tr>
                   <td :class="gishuun_bus.id == active_gishuun_bus_bagts_id && active_gishuun_bus_type == 1 ? 'per_cost1 fill33 active-bagts' : 'per_cost1 fill33'">
                     <Icon class="ivu-icon-md-arrow-round-forward " :style="`color: ${gishuun_bus.ongo}`"></Icon>
                     <span v-if="gishuun_bus.id == active_gishuun_bus_bagts_id && active_gishuun_bus_type == 1" class="span">{{'avsan_bagts'| translate}}</span>
                     <span v-else class="span">{{ gishuun_bus.une_neg }}</span>
                     <Poptip class="pop_tip"
                             confirm
                             :title="'ta_ene_bagts_avah_uu' | translate "
                             :ok-text="'tiim' | translate"
                             :cancel-text="'ugui' | translate"
                             @on-ok="buyBagts(gishuun_bus.id, gishuun_bus.une_neg,1)"
                     >
                       <Button :style="`background-color: ${gishuun_bus.ongo}`">{{'bagts_avah' | translate}}</Button>
                     </Poptip>
                   </td>
                   <td :class="gishuun_bus.id == active_gishuun_bus_bagts_id && active_gishuun_bus_type == 2 ? 'per_cost1 fill33 active-bagts' : 'per_cost1 fill33'">
                     <Icon class="ivu-icon-md-arrow-round-forward " :style="`color: ${gishuun_bus.ongo}`"></Icon>
                     <span v-if="gishuun_bus.id == active_gishuun_bus_bagts_id && active_gishuun_bus_type == 2" class="span">{{'avsan_bagts'| translate}}</span>
                     <span v-else class="span">{{ gishuun_bus.une_hoyor }}</span>
                     <Poptip class="pop_tip"
                             confirm
                             :title="'ta_ene_bagts_avah_uu' | translate "
                             :ok-text="'tiim' | translate"
                             :cancel-text="'ugui' | translate"
                             @on-ok="buyBagts(gishuun_bus.id, gishuun_bus.une_hoyor,2)"
                     >
                       <Button  :style="`background-color: ${gishuun_bus.ongo}`">{{'bagts_avah' | translate}}</Button>
                     </Poptip>
                   </td>
                   <td :class="gishuun_bus.id == active_gishuun_bus_bagts_id && active_gishuun_bus_type == 3 ? 'per_cost1 fill33 active-bagts' : 'per_cost1 fill33'">
                     <Icon class="ivu-icon-md-arrow-round-forward " :style="`color: ${gishuun_bus.ongo}`"></Icon>
                     <span v-if="gishuun_bus.id == active_gishuun_bus_bagts_id && active_gishuun_bus_type == 3" class="span">{{'avsan_bagts'| translate}}</span>
                     <span v-else class="span">{{ gishuun_bus.une_gurav }}</span>
                     <Poptip class="pop_tip"
                             confirm
                             :title="'ta_ene_bagts_avah_uu' | translate "
                             :ok-text="'tiim' | translate"
                             :cancel-text="'ugui' | translate"
                             @on-ok="buyBagts(gishuun_bus.id, gishuun_bus.une_gurav, 3)"
                     >
                       <Button :style="`background-color: ${gishuun_bus.ongo}`">{{'bagts_avah' | translate}}</Button>
                     </Poptip>
                   </td>
                 </tr>
                 </tbody>
               </table>
             </div>
             <div class="text_dec_cont" v-if="gishuun_bus.id == active_gishuun_bus_bagts_id">
               <p>{{'shiljuulgiin_medeelel' | translate}}</p>
               <div class="texts" :style="`border: 1px solid ${gishuun_bus.ongo}`">
                 <table>
                   <tr>
                     <td>{{'shiljuuleh_dans' | translate}}:</td>
                     <td> {{'hudaldaa_hugjliin_bank' | translate}}</td>
                   </tr>
                   <tr>
                     <td>{{'huleen_avagch' | translate}}:</td>
                     <td>{{'mnca' | translate}}</td>
                   </tr>
                   <tr>
                     <td>{{'guilgeenii_utga' | translate}}:</td>
                     <td><b>{{user.id}}-{{ active_gishuun_bus_id }}</b></td>
                   </tr>
                   <tr>
                     <td>{{'tuluv' | translate}}:</td>
                     <td>
                       <span v-if="active_gishuun_bus_tolov == 1">{{'huleegdej_bui' | translate}}</span>
                       <span v-if="active_gishuun_bus_tolov == 2">{{'batalgaajsan' | translate}}</span>
                       <span v-if="active_gishuun_bus_tolov == 3">{{'butsaagdsan' | translate}}</span>
                     </td>
                   </tr>
                   <tr>
                     <td>{{'huchintei_hugatsaa' | translate}}:</td>
                     <td>
                       {{active_gishuun_bus_e_gonoo}} - {{active_gishuun_bus_d_gonoo}}

                     </td>
                   </tr>
                 </table>
               </div>
             </div>
           </div>
         </div>
       </div>
     </div>
   </div>
</template>

<script>
import {GET_GISHUUN_BUS_BAGTS_ANGILAL_VIEW, IMAGE_URL} from "../graphql/queries";
import {mapGetters} from "vuex";

export default {
   name: "gishuun_bus_bagts",
  data() {
    return {

      categorys: [],
      answer_quistions: [],
      activeItem: 0,
      activeCategory: 1,
      gishuun_bagts: [],
      gishuun_bus_bagtsArray: [],
      IMAGE_URL:IMAGE_URL,
      active_gishuun_bagts_id:null,
      active_gishuun_bus_bagts_id:null,
      active_gishuun_bus_type:null,
      active_gishuun_bus_id:null,
      active_gishuun_bus_tolov:null,
      active_gishuun_bus_e_gonoo:null,
      active_gishuun_bus_d_gonoo:null,
    }
  },
  computed: {
    ...mapGetters([
      'languages',
      'language',
      'user',
      // ...
    ])
  },
  mounted() {
    this.getGISHUUN_BUS_BAGTS();
  },
  methods: {
    getGISHUUN_BUS_BAGTS() {
      this.loading = true;
      this.$apollo.query({query: GET_GISHUUN_BUS_BAGTS_ANGILAL_VIEW}).then(({data}) => {
        this.gishuun_bus_bagtsArray = data.ds_bagts_gishuun_bus_view;
        this.loading = false;
      })
    },
  }
}
</script>

<style scoped>

</style>