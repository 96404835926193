<template>
    <div class="uilchilgeenii_nuhtsul">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="uilchilgeenii_nuhtsul_title"><span class="yelow">{{ 'uilchilgeenii' | translate
                        }}</span>{{ 'nuhtsul' | translate }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="uilchilgeenii_nuhtsul_slide">
                        <Tabs value="name1">
                            <TabPane :label="'uilchilgeenii_nukhtsul' | translate" name="name1">
                                <div v-if="loading" class="loading-wrap">
                                    <div class="lds-ellipsis">
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                    </div>
                                </div>
                                <div class="answer_and_quistion">
                                    <div class="row">
                                        <div class="col-md-3">
                                            <ul class="no-list-style">
                                                <li :key="index" v-for="(cat_data, index) in categorys">
                                                    <a href="javascript:void(0);" class="item"
                                                       :class="[(index+1===activeCategory) ? 'visible':'']"
                                                       @click="callData(cat_data.id)">
                                                        <span v-if="language.short == 'mn'">{{ cat_data.term_mn
                                                            }}</span>
                                                        <span v-else>{{ cat_data.term_en }}</span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="col-md-9">
                                            <Collapse  accordion>
                                                <Panel  v-for="(nohtsol, q_index) in answer_quistions" :key="q_index" :value="q_index" >
                                                    <span  v-if="language.short == 'mn'">{{nohtsol.zaalt}}</span>
                                                    <span v-else >{{nohtsol.zaalt_en}}</span>
                                                    <p slot="content" v-if="language.short == 'mn'" v-html="nohtsol.nohtsol_mn"></p>
                                                    <p slot="content" v-else v-html="nohtsol.nohtsol_en"></p>
                                                </Panel>
                                            </Collapse>
                                        </div>
                                    </div>
                                </div>
                            </TabPane>
                            <!--ГИШҮҮДИЙН БАГЦ-->
                            <TabPane :label="'gishuun_bagts' | translate" name="name2">
                                <div class="row gishuudiin_bagts_slide ">
                                    <div class="col-md-4 col_item" v-for="(gishuun, uil_index) in gishuun_bagts"
                                         :key="uil_index">
                                        <div class="gold_silver_bronze">
                                            <div class="gold_image_logo ">
                                                <img :src="IMAGE_URL + gishuun.icon">
                                            </div>
                                            <div class="gold_header" :style="`background-color: ${gishuun.color}`">
                                                <div class="head_text">
                                                    <h5>{{ gishuun.torol }}</h5>
                                                    <span>({{ gishuun.bagts_ner }})</span>
                                                </div>
                                            </div>
                                            <div class="col_body1">
                                                <p class="text_Body">{{ 'service_date' | translate }} :
                                                    <span> {{ gishuun.gishuun_jil }} </span></p>
                                                <p class="text_Body">{{ 'job_posting' | translate }} :
                                                    <span> {{ gishuun.ajiliin_Zar }}  </span></p>
                                                <p class="text_Body">{{ 'application_base' | translate }} :
                                                    <span> {{ gishuun.anket }} </span>
                                                </p>
                                                <div class="last_text">
                                                    {{ 'other' | translate }}:
                                                    <ul class="text_list" v-html="gishuun.busad">
                                                    </ul>
                                                </div>
                                            </div>
                                            <div :class="gishuun.id == active_gishuun_bagts_id ? 'col_footer active-bagts' : 'col_footer'">
                                                <p v-if="gishuun.id == active_gishuun_bagts_id">
                                                    {{ 'idevhtei_bagts' | translate }}</p>
                                                <p v-else>{{ gishuun.bagts_unelgee }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </TabPane>
                            <!--ГИШҮҮН БУС БАГЦ-->
                            <TabPane :label="'gishuun_bus_bagts' | translate" name="name3">
                                <div class="row gishuun_bus_bagts_slide ">
                                    <div class="col-md-4 col_item" v-show="gishuun_bus.torol_id != 4"
                                         v-for="(gishuun_bus, uilchilgee_index) in gishuun_bus_bagtsArray"
                                         :key="uilchilgee_index">
                                        <div class="gold_silver_bronze">
                                            <div class="gold_header " :style="`background-color: ${gishuun_bus.ongo}`">
                                                <div class="head_text">
                                                    <h5>{{ gishuun_bus.torol }}</h5>
                                                </div>
                                            </div>
                                            <div class="col_body1">
                                                <div class="text_Body">{{ 'service_date' | translate }} :
                                                    <table>
                                                        <tbody>
                                                        <tr>
                                                            <td>{{ gishuun_bus.gishuun_jil }}</td>
                                                            <td>{{ gishuun_bus.sar }}</td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div class="text_Body">{{ 'job_posting' | translate }} :
                                                    <table>
                                                        <tbody>
                                                        <!--tr-->
                                                        <tr>
                                                            <td>{{ gishuun_bus.ajli_zar_jil }}</td>
                                                            <td>{{ gishuun_bus.ajil_zar_sar }}</td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <p class="p_text_Body">{{ 'application_base' | translate }} :
                                                    <span> {{ gishuun_bus.anket }} </span>
                                                </p>
                                                <div class="last_text">
                                                    {{ 'other' | translate }}:
                                                    <ul class="text_list" v-html="gishuun_bus.busad"></ul>
                                                </div>
                                                <div class="last_text-body">{{ 'package_service1' | translate }} :
                                                    <table>
                                                        <tbody>
                                                        <!--tr-->
                                                        <tr>
                                                            <td :class="gishuun_bus.id == active_gishuun_bus_bagts_id && active_gishuun_bus_type == 1 ? 'sum_cost1 active-bagts' : 'sum_cost1'">
                                                                <Icon class="ivu-icon-md-arrow-round-forward "
                                                                      :style="`color: ${gishuun_bus.ongo}`"></Icon>&nbsp;
                                                                <span v-if="gishuun_bus.id == active_gishuun_bus_bagts_id && active_gishuun_bus_type == 1"
                                                                      class="span">{{ 'avsan_bagts'| translate }}</span>
                                                                <span v-else class="span">{{ gishuun_bus.bagts_une_jil
                                                                    }} </span>

                                                                <Poptip class="pop_tip"
                                                                        confirm
                                                                        :title="'ta_ene_bagts_avah_uu' | translate "
                                                                        :ok-text="'tiim' | translate"
                                                                        :cancel-text="'ugui' | translate"
                                                                        @on-ok="buyBagts(gishuun_bus.id, gishuun_bus.bagts_une_jil, 1)"
                                                                >
                                                                    <Button :style="`background-color: ${gishuun_bus.ongo}`">
                                                                        {{ 'bagts_avah' | translate }}
                                                                    </Button>
                                                                </Poptip>
                                                            </td>
                                                            <td :class="gishuun_bus.id == active_gishuun_bus_bagts_id && active_gishuun_bus_type == 2 ? 'per_cost1 active-bagts' : 'per_cost1'">
                                                                <Icon class="ivu-icon-md-arrow-round-forward "
                                                                      :style="`color: ${gishuun_bus.ongo}`"></Icon>&nbsp;

                                                                <span v-if="gishuun_bus.id == active_gishuun_bus_bagts_id && active_gishuun_bus_type == 2"
                                                                      class="span">{{ 'avsan_bagts'| translate }} </span>
                                                                <span v-else class="span">{{ gishuun_bus.bagts_une_sar
                                                                    }}</span>
                                                                <Poptip class="pop_tip"
                                                                        confirm
                                                                        :title="'ta_ene_bagts_avah_uu' | translate "
                                                                        :ok-text="'tiim' | translate "
                                                                        :cancel-text="'ugui' | translate "
                                                                        @on-ok="buyBagts(gishuun_bus.id, gishuun_bus.bagts_une_sar, 2)"
                                                                >
                                                                    <Button :style="`background-color: ${gishuun_bus.ongo}`">
                                                                        {{ 'bagts_avah' | translate }}
                                                                    </Button>
                                                                </Poptip>

                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div class="text_dec_cont"
                                                     v-if="gishuun_bus.id == active_gishuun_bus_bagts_id">
                                                    <p>{{ 'shiljuulgiin_medeelel' | translate }}</p>
                                                    <div class="texts" :style="`border: 1px solid ${gishuun_bus.ongo}`">
                                                        <table>
                                                            <tr>
                                                                <td>{{ 'shiljuuleh_dans' | translate }}:</td>
                                                                <td> {{ 'hudaldaa_hugjliin_bank' | translate }}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>{{ 'huleen_avagch' | translate }}:</td>
                                                                <td> {{ 'mnca' | translate }}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>{{ 'guilgeenii_utga' | translate }}:</td>
                                                                <td><b>{{ user.id }}-{{ active_gishuun_bus_id }}</b>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>{{ 'tuluv' | translate }}:</td>
                                                                <td>
                                                                    <span v-if="active_gishuun_bus_tolov == 1">{{ 'huleegdej_bui' | translate }}</span>
                                                                    <span v-if="active_gishuun_bus_tolov == 2">{{ 'batalgaajsan' | translate }}</span>
                                                                    <span v-if="active_gishuun_bus_tolov == 3">{{ 'butsaagdsan' | translate }}</span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>{{ 'huchintei_hugatsaa' | translate }}:</td>
                                                                <td>
                                                                    {{ active_gishuun_bus_e_gonoo }} -
                                                                    {{ active_gishuun_bus_d_gonoo }}

                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <gishuun_bus_bagts></gishuun_bus_bagts>
                                </div>
                            </TabPane>
                        </Tabs>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import {mapGetters} from "vuex";
import {
    GET_UILCHILGEENII_NUKHTSUL_ANGILAL_VIEW,
    GET_UILCHILGEENII_NUKHTSUL_VIEW_BY_ID,
    GET_GISHUUN_BAGTS_ANGILAL_VIEW,
    GET_GISHUUN_BUS_BAGTS_ANGILAL_VIEW,
    IMAGE_URL,


} from "../graphql/queries";
import {
    GET_ORG_BAGTS,
    GET_BRIGAD_BAGTS, BUYE_BAGTS
} from "../graphql/anket";

import {today, afterMonth, afterYear} from "../utils/date"
import gishuun_bus_bagts from './gishuun_bus_bagts';

export default {
    name: "uilchilgeenii_nuhtsul",
    components: {
        gishuun_bus_bagts: gishuun_bus_bagts,
    },
    data() {
        return {
            tab: 'name1',
            value1: '1',
            loading: true,
            categorys: [],
            answer_quistions: [],
            activeItem: 0,
            activeCategory: 1,
            gishuun_bagts: [],
            gishuun_bus_bagtsArray: [],
            IMAGE_URL: IMAGE_URL,
            active_gishuun_bagts_id: null,
            active_gishuun_bus_bagts_id: null,
            active_gishuun_bus_type: null,
            active_gishuun_bus_id: null,
            active_gishuun_bus_tolov: null,
            active_gishuun_bus_e_gonoo: null,
            active_gishuun_bus_d_gonoo: null,
        }
    },
    computed: {
        ...mapGetters([
            'languages',
            'language',
            'user',
            // ...
        ])
    },
    mounted() {
        this.getGET_category();
        this.callData();
        this.getGISHUUN_BAGTS();
        this.getGISHUUN_BUS_BAGTS();
        this.bagtsData();
    },
    methods: {
        buyBagts(bagtsID, une, type) {

            if (this.user.role == "3" || this.user.role == "4") {
                let e_ognoo = today();
                let d_ognoo = afterMonth();
                if (type == 1) {
                    d_ognoo = afterYear();
                }
                if (bagtsID == 5) {
                    d_ognoo = afterMonth();
                }
                this.$apollo.mutate({
                    mutation: BUYE_BAGTS,
                    variables: {
                        bagts_id: bagtsID,
                        brigad_id: this.user.brigad_id ? this.user.brigad_id * 1 : 0,
                        baiguullaga_id: this.user.org_id ? this.user.org_id * 1 : 0,
                        e_ognoo: e_ognoo,
                        d_ognoo: d_ognoo,
                        une: une,
                        sub_type: type,
                    },
                }).then(({data}) => {

                    this.active_gishuun_bus_bagts_id = data.createSubZarBagtsGishuunBus.bagts_id;
                    this.active_gishuun_bus_type = data.createSubZarBagtsGishuunBus.sub_type;
                    this.active_gishuun_bus_id = data.createSubZarBagtsGishuunBus.id;
                    this.active_gishuun_bus_tolov = data.createSubZarBagtsGishuunBus.tolov_id;
                    this.active_gishuun_bus_e_gonoo = data.createSubZarBagtsGishuunBus.e_ognoo;
                    this.active_gishuun_bus_d_gonoo = data.createSubZarBagtsGishuunBus.d_ognoo;
                    this.$Notice.success({
                        title: 'Амжилттай',
                        desc: 'Таны хүсэлтийг илгээгдлээ'
                    });

                })
            } else {
                this.$Notice.warning({
                    title: 'Алдаа',
                    desc: 'Байгууллага эсвэл бригадын хэрэглэгч багц авах боломжтой'
                });
            }


        },
        bagtsData() {
            if (this.user.role == "3" || this.user.role == "4") {
                if (this.user.role == "3") {
                    this.$apollo.query({
                        query: GET_ORG_BAGTS,
                        variables: {baiguullaga_id: this.user.org_id.toString()}
                    }).then(({data}) => {
                        if (data.sub_zar_bagts_gishuun.length >= 1) {
                            this.active_gishuun_bagts_id = data.sub_zar_bagts_gishuun[0].bagts_id;
                        }
                        if (data.sub_zar_bagts_gishuun_bus.length >= 1) {
                            this.active_gishuun_bus_bagts_id = data.sub_zar_bagts_gishuun_bus[0].bagts_id;
                            this.active_gishuun_bus_type = data.sub_zar_bagts_gishuun_bus[0].sub_type;
                            this.active_gishuun_bus_id = data.sub_zar_bagts_gishuun_bus[0].id;
                            this.active_gishuun_bus_tolov = data.sub_zar_bagts_gishuun_bus[0].tolov_id;
                            this.active_gishuun_bus_e_gonoo = data.sub_zar_bagts_gishuun_bus[0].e_ognoo;
                            this.active_gishuun_bus_d_gonoo = data.sub_zar_bagts_gishuun_bus[0].d_ognoo;
                        }
                    });
                } else if (this.user.role == "4") {
                    this.$apollo.query({
                        query: GET_BRIGAD_BAGTS,
                        variables: {brigad_id: this.user.brigad_id.toString()}
                    }).then(({data}) => {
                        if (data.sub_zar_bagts_gishuun.length >= 1) {
                            this.active_gishuun_bagts_id = data.sub_zar_bagts_gishuun[0].bagts_id;
                        }
                        if (data.sub_zar_bagts_gishuun_bus.length >= 1) {
                          this.active_gishuun_bus_bagts_id = data.sub_zar_bagts_gishuun_bus[0].bagts_id;
                          this.active_gishuun_bus_type = data.sub_zar_bagts_gishuun_bus[0].sub_type;
                          this.active_gishuun_bus_id = data.sub_zar_bagts_gishuun_bus[0].id;
                          this.active_gishuun_bus_tolov = data.sub_zar_bagts_gishuun_bus[0].tolov_id;
                          this.active_gishuun_bus_e_gonoo = data.sub_zar_bagts_gishuun_bus[0].e_ognoo;
                          this.active_gishuun_bus_d_gonoo = data.sub_zar_bagts_gishuun_bus[0].d_ognoo;
                        }
                    });
                }
            }
        },
        getGET_category() {
            this.loading = true;
            this.$apollo.query({query: GET_UILCHILGEENII_NUKHTSUL_ANGILAL_VIEW}).then(({data}) => {
                this.categorys = data.ds_uilchilgee_angilal_view;
                this.loading = false;
            })
        },
        paymentActive(p) {
            this.activeItem = p;
        },
        callData(cat_id) {
            this.currentCat = cat_id;
            this.activeCategory = cat_id;
            this.loading = true;
            if (this.currentCat) {
                this.$apollo.query({
                    query: GET_UILCHILGEENII_NUKHTSUL_VIEW_BY_ID,
                    variables: {cat_id: this.currentCat.toString()}
                }).then(({data}) => {
                    this.answer_quistions = data.ds_uilchilgeenii_nohtsol_view;
                    setTimeout(() => {
                        this.loading = false;
                    })
                });
            } else {
                this.activeCategory = 2;
                this.$apollo.query({
                    query: GET_UILCHILGEENII_NUKHTSUL_VIEW_BY_ID,
                    variables: {cat_id: this.activeCategory.toString()}
                }).then(({data}) => {
                    this.answer_quistions = data.ds_uilchilgeenii_nohtsol_view;
                    setTimeout(() => {
                        this.loading = false;
                    })
                });
            }
        },

        getGISHUUN_BAGTS() {
            this.loading = true;
            this.$apollo.query({query: GET_GISHUUN_BAGTS_ANGILAL_VIEW}).then(({data}) => {
                this.gishuun_bagts = data.ds_bagts_view;
                this.loading = false;
            })
        },

        getGISHUUN_BUS_BAGTS() {
            this.loading = true;
            this.$apollo.query({query: GET_GISHUUN_BUS_BAGTS_ANGILAL_VIEW}).then(({data}) => {
                this.gishuun_bus_bagtsArray = data.ds_bagts_gishuun_bus_view;
                this.loading = false;
            })
        },

    },
}
</script>
